<template>
    <div class="grid grid-cols-1 gap-4 py-2 sm:py-8 text-xs sm:text-sm text-gray-500 text-justify font-light">
        <p>Niniejszy materiał nie stanowi oferty w rozumieniu art. 66 K.C. i ma charakter wyłącznie informacyjny.
            Ostateczne warunki umowy zależą od oceny zdolności finansowanej oraz finansowanego przedmiotu i zostaną
            wskazane w odpowiedniej umowie, po złożeniu wniosku.
        </p>
        <p>Kalkulacja przygotowana przez: VEHIS Finanse Sp. z o.o., 40154 Katowice, Al. Korfantego 141, KRS
            0000791146, NIP: 5272896510, kapitał zakładowy 385000
        </p>
    </div>
    <div class="grid grid-cols-1 gap-4 py-2 sm:py-8 text-xs sm:text-sm text-gray-500 text-justify font-light">
                <div>
                    <strong>Administratorem danych osobowych</strong> jest VEHIS Finanse sp. z o.o. <strong>dane
                        kontaktowe:</strong> tel. 32 308 84 44, <a href="mailto:bok@vehis.pl"><span class="underline" style="color: blue !important;">bok@vehis.pl</span></a>,
                    listownie na adres:
                    al. Wojciecha Korfantego 141, 40-154 Katowice dalej: VEHIS Finanse oraz VEHIS sp. z o.o. <strong>dane
                        kontaktowe:</strong> tel. 32 308 84 44, bok@vehis.pl, listownie na adres al. Wojciecha
                    Korfantego 141, 40-154 Katowice, dalej: VEHIS. VEHIS i VEHIS Finanse są współadministratorami w
                    rozumieniu art. 26 ust. 1 RODO, wspólnie ustalając cele i sposoby przetwarzania danych osobowych w
                    ramach procesu zawierania i obsługi umów dotyczących oferowanych przez każdy z tych podmiotów usług.
                </div>
                <div>
                    <strong>Dane kontaktowe Inspektora Ochrony Danych VEHIS oraz VEHIS Finanse:</strong> <a href="mailto:iod@vehis.pl"><span class="underline" style="color: blue !important;">iod@vehis.pl</span></a>, ul.
                    al. Wojciecha Korfantego 141, 40-154 Katowice z dopiskiem „IOD”.
                </div>
                <div>
                    Współadministratorzy wyznaczyli punkt kontaktowy, do którego każdy podmiot danych może się zwracać z
                    każdą sprawą związaną z przetwarzaniem danych osobowych przez współadministratorów. Punktem
                    kontaktowym jest: VEHIS sp. z o.o. (VEHIS). Kontakt z punktem kontaktowym dla podmiotów danych jest
                    możliwy pod adresem: <a href="mailto:iod@vehis.pl"><span class="underline" style="color: blue !important;">iod@vehis.pl</span></a>.
                </div>
                <div>
                    Współadministratorzy dokonali wspólnych uzgodnień, o których mowa w art. 26 ust. 1 RODO. W ramach
                    tych uzgodnień ustalili m.in., że podmiotem odpowiedzialnym za wypełnianie obowiązków wynikających z
                    RODO w odniesieniu do wykonywania przez podmioty danych przysługujących im praw jak również
                    obowiązku informacyjnego (art. 13-14 RODO) w ramach procesów objętych współadministrowaniem, będzie
                    VEHIS.
                </div>
                <div>
                    <strong>Dane osobowe są przetwarzane przez VEHIS Finanse i VEHIS w celu:</strong>
                </div>
                <div>
                    <ul class="list-none">
                        <li>
                            <div class="flex items-start">
                                <span class="mr-2">1.</span>
                                <span class="ml-6">kalkulacji i przygotowania oferty, w celu zawarcia (w tym oceny możliwości zawarcia) oraz wykonania zawartej umowy – na podstawie art. 6 ust. 1 lit. b RODO (a dla podmiotów danych nie będących stroną transakcji – na podstawie art. 6 ust. 1 lit. f RODO) przez okres niezbędny do zawarcia i wykonania umowy,</span>
                            </div>
                        </li>
                        <li>
                            <div class="flex items-start">
                                <span class="mr-2">2.</span>
                                <span class="ml-6">wykonania obowiązków prawnych ciążących na danym administratorze, wynikających z obowiązujących przepisów prawa – w szczególności z przepisów o przeciwdziałaniu praniu pieniędzy oraz finansowaniu terroryzmu, przepisów rachunkowo – podatkowych, przepisów dotyczących obowiązku rozpatrywania reklamacji – na podstawie art. 6 ust. 1 lit. c RODO, przez okres niezbędny do wykonania tych obowiązków, wskazany w przepisach,</span>
                            </div>
                        </li>
                        <li>
                            <div class="flex items-start">
                                <span class="mr-2">3.</span>
                                <span class="ml-6">wykonywania prawnie uzasadnionych interesów każdego z administratorów w postaci: marketingu bezpośredniego produktów i usług własnych, statystycznych i analitycznych (służących m.in. zapewnieniu bezpieczeństwa prowadzonej działalności i jej usprawnianiu), ustalenia, dochodzenia, obrony roszczeń lub obrony przed roszczeniami przez danego administratora, przedstawiania przez VEHIS jako agenta ubezpieczeniowego ofert różnych Towarzystw Ubezpieczeniowych, pośredniczenia przy zawieraniu umów ubezpieczenia oraz ich obsługi – na podstawie art. 6 ust.1 lit. f RODO, tak długo jak aktualny będzie interes administratora lub do momentu wniesienia usprawiedliwionego sprzeciwu.</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div>
                    <strong>Podanie danych osobowych jest dobrowolne</strong>, jest jednak konieczne do przedstawienia
                    oferty, jak również zawarcia oraz wykonywania umowy z VEHIS Finanse oraz VEHIS i/lub umowy będącej jej
                    zabezpieczeniem, jak również do przedstawienia przez VEHIS ofert różnych towarzystw
                    ubezpieczeniowych.
                </div>
                <div>
                    <strong>VEHIS Finanse oraz VEHIS przetwarzają następujące kategorie Pani/Pana danych
                        osobowych:</strong> dane identyfikujące tożsamość (imię i nazwisko, numer NIP i in.), dane
                    teleadresowe, obywatelstwo, stan cywilny, dane o posiadanym majątku i zobowiązaniach, dane o ustroju
                    majątkowym małżeńskim, dane o zatrudnieniu i uzyskiwanych dochodach (w przypadku Klientów
                    posiadających status konsumenta), przy czym jeżeli jest Pani/Pan wyłącznie reprezentantem klienta
                    VEHIS oraz VEHIS Finanse, wyłącznie następujące kategorie danych: dane identyfikujące tożsamość (imię i
                    nazwisko, numer NIP i in.), dane teleadresowe, które to dane nie są przetwarzane przez te podmioty w
                    sposób zautomatyzowany.
                </div>
                <div>
                    VEHIS Finanse oraz VEHIS pozyskują Pani/Pana dane osobowe bezpośrednio od Pani/Pana, od podmiotu
                    składającego wniosek o zawarcie umowy lub jego reprezentanta albo od przedstawiciela VEHIS i VEHIS
                    Finanse przedstawiającego ofertę, jak również z publicznie dostępnych baz i rejestrów (takich jak KRS,
                    CEIDG i in.), przy czym VEHIS Finanse także z Biura Informacji Kredytowej oraz biur informacji
                    gospodarczej.
                </div>
                <div>
                    Pani/Pana dane osobowe mogą zostać udostępnione innym podmiotom, gdy obowiązek taki wynika z
                    przepisów prawa (np. GIIF, Szef KAS), gdy jest to prawnie uzasadniony interes VEHIS Finanse lub VEHIS
                    bądź interes podmiotu trzeciego lub gdy wyrazi Pani/Pan na to zgodę (na tej podstawie dane mogą
                    zostać przekazane przez VEHIS Finanse do BIK oraz biur informacji gospodarczej oraz do spółek
                    powiązanych z VEHIS Finanse i VEHIS). W związku z uzasadnionym interesem VEHIS Finanse oraz VEHIS mogą
                    ujawnić Pani/Pana dane osobowe podmiotom wspierającym ich bieżącą działalność takim jak m.in.
                    dostawcy usług IT, usług zarządzania i doradczych, usług windykacyjnych, pocztowych i kurierskich,
                    archiwizowania i niszczenia danych i in.
                </div>
                <div>
                    <strong>W związku z przetwarzaniem przysługują Pani/Panu następujące uprawnienia względem VEHIS Finanse
                        oraz VEHIS (które może Pani/Pan wykonywać zarówno wobec VEHIS jak i VEHIS Finanse):</strong>
                    dostępu do danych, żądania sprostowania, usunięcia, ograniczenia przetwarzania, przenoszenia danych,
                    sprzeciwu co do ich przetwarzania, wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych,
                    wycofania zgody na przetwarzanie - które pozostanie bez wpływu na dotychczasowe przetwarzanie – na
                    zasadach wynikających m.in. z art. 15-20 RODO.
                </div>
                <div>
                    <strong><u>Poniższa klauzula informacyjna BIK ma zastosowanie wyłącznie do następujących kategorii
                            podmiotów danych: Klient, poręczyciel Klienta, wspólnik Klienta będącego spółką
                            cywilną/jawną/partnerską, komplementariusz Klienta, o ile podmioty te wyraziły zgodę na
                            przekazanie ich danych do BIK.</u></strong>
                </div>
                <div>
                    <strong>KLAUZULA INFORMACYJNA – Biura Informacji Kredytowej S.A.</strong> z siedzibą w Warszawie,
                    ul. Zygmunta Modzelewskiego 77 A (dalej jako „BIK”),
                </div>
                <div>
                    Wyrażenie przez Panią/Pana zgody, oznacza, że Pani/Pana dane osobowe mogą zostać przekazane do BIK w
                    celu udostępnienia przez BIK do VEHIS Finanse, w odpowiedzi na złożone zapytanie, informacji
                    dotyczących Pani/Pana, w tym stanowiących tajemnicę bankową, dla oceny zdolności kredytowej i
                    analizy ryzyka kredytowego i/lub w celu przetwarzania przez BIK danych dotyczących Pani/Pana
                    zobowiązania wynikającego z zawartej z VEHIS Finanse umowy, który stanie się ich administratorem.
                </div>
                <div>
                    <strong>Z BIK można się skontaktować na</strong> adres e-mail: kontakt@bik.pl, lub pisemnie (Centrum
                    Obsługi Klienta BIK S.A., 02-676 Warszawa, ul. Postępu 17A). Kontakt z inspektorem ochrony danych
                    osobowych możliwy jest na adres e-mail: iod@bik.pl lub pisemnie (Inspektor Ochrony Danych, Biuro
                    Informacji Kredytowej S.A., 02-679 Warszawa, ul. Zygmunta Modzelewskiego 77A).
                </div>
                <div>
                    BIK przetwarza Pani/Pana dane osobowe: <strong>a) w celu oceny zdolności kredytowej</strong> i
                    analizy ryzyka kredytowego, w tym z wykorzystaniem profilowania – na podstawie Pani/Pana zgody,
                    przez okres trwania Pani/Pana zobowiązania lub po jego wygaśnięciu – w każdym przypadku nie dłużej
                    niż przez okres 5 lat po wygaśnięciu zobowiązania, a w zakresie danych wynikających z przekazanego
                    zapytania – nie dłużej niż 2 lata od jego przekazania; <strong>b) w celu statystycznym i
                        analiz</strong>, których wynikiem nie są dane osobowe i wynik ten nie służy za podstawę
                    podejmowania decyzji dotyczącej konkretnej osoby fizycznej – na podstawie prawnie uzasadnionego
                    interesu polegającego na przygotowywaniu dla potrzeb banków instytucji pożyczkowych i innych
                    instytucji upoważnionych (odbiorców danych) produktów oraz usług wspomagających i umożliwiających
                    właściwe zarządzanie rachunkiem i portfelem kredytowym (pożyczkowym) klientów, nie dłużej przez
                    okres wskazany w pkt a), <strong>c) w celu rozpatrywania Pani/Pana potencjalnych reklamacji</strong>
                    i zgłoszonych roszczeń – na podstawie prawnie uzasadnionego interesu BIK, polegającego na ich
                    rozpatrzeniu i obronie przez potencjalnymi roszczeniami, do momentu przedawnienia Pani/Pana
                    potencjalnych roszczeń z tytułu naruszenia przetwarzania danych.
                </div>
                <div>
                    <strong>BIK przetwarza następujące kategorie</strong> Pani/Pana danych osobowych, które pozyskuje z
                    VEHIS Finanse: <strong>dane identyfikujące osobę</strong>, w tym: PESEL, imiona, nazwiska, dane
                    dotyczące dokumentów tożsamości, data i miejsce urodzenia, płeć, obywatelstwo; <strong>dane adresowe
                        i teleadresowe, dane dotyczące zobowiązania</strong>, w tym: cel i źródło zobowiązania, kwota i
                    waluta, stan zadłużenia, okres i warunki spłaty zobowiązania, zabezpieczenie prawne, przebieg
                    realizacji zobowiązania, dane dotyczące wniosków kredytowych.
                </div>
                <div>
                    <strong>Pani/Pana dane osobowe mogą być udostępniane</strong> przez BIK podmiotom uprawnionym do ich
                    otrzymania na podstawie Pana/Pani zgody tj. bankom, instytucjom ustawowo upoważnionym do udzielania
                    kredytów, instytucjom kredytowym, instytucjom pożyczkowym i podmiotom, o których mowa w art. 59d
                    ustawy z dnia 12 maja 2011 r. o kredycie konsumenckim a także do rejestru PESEL lub Rejestru Dowodów
                    Osobistych w celu weryfikacji poprawności przekazanych danych osobowych. Pani/Pana dane osobowe mogą
                    być także przekazywane podmiotom przetwarzającym dane w imieniu BIK, takim jak dostawcy usług
                    informatycznych – przy czym takie podmioty przetwarzają dane jako podwykonawcy, na podstawie umowy z
                    BIK i wyłącznie zgodnie z poleceniami BIK.
                </div>
                <div>
                    Przysługuje Pani/Panu prawo dostępu do Pani/Pana danych oraz prawo żądania ich sprostowania,
                    usunięcia, ograniczenia przetwarzania. W zakresie, w jakim podstawą przetwarzania Pani/Pana danych
                    osobowych jest przesłanka prawnie uzasadnionego interesu administratora, przysługuje Pani/Panu prawo
                    wniesienia sprzeciwu wobec przetwarzania Pani/Pana danych osobowych.
                </div>
                <div>
                    W zakresie, w jakim podstawą przetwarzania Pani/Pana danych osobowych jest zgoda, ma Pani/Pan prawo
                    wycofania zgody. Wycofanie zgody nie ma wpływu na zgodność z prawem przetwarzania, którego dokonano
                    na podstawie zgody przed jej wycofaniem.
                </div>
                <div>
                    W zakresie, w jakim Pani/Pana dane są przetwarzane w celu zawarcia i wykonywania umowy lub
                    przetwarzane na podstawie zgody – przysługuje Pani/Panu także prawo do przenoszenia danych
                    osobowych, tj. do otrzymania od administratora Pani/Pana danych osobowych, w ustrukturyzowanym,
                    powszechnie używanym formacie nadającym się do odczytu maszynowego. Może Pani/Pan przesłać te dane
                    innemu administratorowi danych. Uprawnienie do przenoszenia danych nie dotyczy danych, które
                    stanowią tajemnicę przedsiębiorstwa.
                </div>
                <div>
                    Przysługuje Pani/Panu również prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną
                    danych osobowych.
                </div>
            </div>
</template>