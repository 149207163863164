<script setup>
const seoContentObject = {

  title: 'O Grupie Vehis',
  fields: {
    field: [
      {
        title: '',
        content: '<p>VEHIS to platforma oferująca samochody wszystkich marek wraz z finansowaniem, ubezpieczeniem oraz wsparciem serwisowym.</p>\n' +
                 '<p>Oferujemy samochody osobowe i dostawcze do 3,5t DMC - nowe i używane do 5 lat. Finansujemy także pojazdy naszych partnerów lub wskazane przez klienta.</p>\n'
      },
      {
        title: '',
        content: '<p>Leasing VEHIS to wyjątkowe połączenie korzyści leasingu i elastyczności najmu długoterminowego. Swoją ofertę kierujemy zarówno do przedsiębiorców, jak i konsumentów.</p>\n' +
                 '<p>Strategicznym inwestorem VEHIS jest Enterprise Investors, jedna z największych firm private equity w Europie. EI zarządza portfelem inwestycyjnym o wartości ponad 2,5 mld €.</p>\n'
      },
    ]
  }
}

const field = (index) => seoContentObject.fields.field[index];
</script>

<template>
  <div class="font-extralight text-sm mt-16">
    <div class="h-0 my-8 mx-auto w-full"></div>
    <h2 class="w-full text-left text-lg lg:text-2xl font-normal mb-4 sm:mb-10 md:mb-6">{{ seoContentObject.title }}</h2>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12 mb-10 relative">
      <SeoContentBlock class="text-lg" :data="field(0)" />
      <SeoContentBlock class="text-lg" :data="field(1)" />
      <div class="hidden lg:block absolute inset-y-0 left-1/2 transform -translate-x-1/2 w-px bg-gray-400"></div>
    </div>

    <!-- <div class="h-0 my-8 mx-auto w-full border-t border-gray-400"></div>
    <div class="grid grid-cols-1 gap-6 mb-6">
      <SeoContentBlock :data="field(2)" />
    </div>
    <div class="h-0 my-8 mx-auto w-full border-t border-gray-400"></div>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
      <SeoContentBlock :data="field(3)" />
      <SeoContentBlock :data="field(4)" />
    </div>
    <div class="h-0 my-8 mx-auto w-full border-t border-gray-400"></div>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
      <SeoContentBlock :data="field(5)" />
      <SeoContentBlock :data="field(6)" />
      <SeoContentBlock :data="field(7)" />
    </div> -->
  </div>
</template>