<script setup>

</script>

<template>
    <footer class="pb-16">
        <div class="text-lg mb-6">
            <p class="font-normal">
                VEHIS sp. z o.o.
            </p>
            <p class="font-extralight">
                al. Korfantego 141, 40-154 Katowice
            </p>
            <p class="font-extralight">
                KRS: 000778463, NIP: 1132995753
            </p>
        </div>
    </footer>
</template>
