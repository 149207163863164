<script setup>
definePageMeta({
  layout: "plain"
})

const badRoutes = useBadRoutes();
const backend = useBackend();
const route = useRoute();

const {data: liczakOffer, error} = await useAsyncData(() => new Promise(async (resolve, fail) => {
  const response = await backend.getCarLiczakOffer(route.params.uuid);
  if (response.data.status === 404) {
    return fail(response.data);
  }
  resolve(response.data.data);
}))
if (error.value) {
  // not works on dev, only on production!
  await badRoutes.handle()
}

const pdfMode = computed(() => !!route.query.pdf || false);
const moreEquipment = ref(false);
const brand = computed(() => liczakOffer.value ? liczakOffer.value.car.brandName : "");
const model = computed(() => liczakOffer.value ? liczakOffer.value.car.modelName : "");
const version = computed(() => liczakOffer.value ? liczakOffer.value.car.versionName : "");

const equipment = computed(() => {
  const rebuilt = {};
  if (!liczakOffer.value.car.equipment) {
    return rebuilt;
  }

  liczakOffer.value.car.equipment.forEach(eq => {
    if (!rebuilt[eq.category]) {
      rebuilt[eq.category] = [];
    }
    rebuilt[eq.category].push(eq.name);
  });

  return Object.fromEntries(
    Object.entries(rebuilt)
      .sort((a, b) => {
        if (a[0] === "Pozostałe") return 1;
        if (b[0] === "Pozostałe") return -1;
        if (a[0] < b[0]) return -1;
        if (a[0] > b[0]) return 1;
        return 0;
      })
  );
})

useHead({
  title: `${brand.value.toUpperCase()} ${model.value.toUpperCase()} - oferta leasingu`,
  meta: [
    {name: 'description', content: `${brand.value.toUpperCase()} ${model.value.toUpperCase()} w leasingu dla przedsiębiorców i konsumentów ✔️ Decyzja leasingowa w 15 min ✔️ Atrakcyjne rabaty ✔️ Sprawdź!`},
    {name: 'robots', content: 'noindex, follow'}
  ],
  link: [
    {rel: 'canonical', href: 'https://vehis.pl' + route.path}
  ]
});

const request = useRequestURL();
const domain = request.href;

const isAbsolute = computed(() => {
  return domain.includes('?pdf=1');
});
const isCarkulacja = computed(() => {
  return domain.includes('carkulacja');
});
const drivePlusHeading = computed(() => {
  return domain.includes('carkulacja') ? 'Twój samochód ochroni pakiet DRIVE+' : 'W leasingu VEHIS Twój samochód ochroni pakiet DRIVE+';
})
const isAdditionalFeesVisible = computed(() => {
  return !!(
    liczakOffer.value?.offer?.additionalFees?.annualFee ||
    liczakOffer.value?.offer?.additionalFees?.costReimbursement ||
    liczakOffer.value?.offer?.additionalFees?.additionalInsuranceCost
  );
});
const isNew = computed(() => {
  return liczakOffer?.value.car.state === 'Nowy';
})
</script>

<template>
  <AppHeaderMinimal :is-absolute="isAbsolute" :custom-logo="isCarkulacja" no-button-call no-link>
    <template #custom-logo-html>
      <VectorCarkulacjaLogo v-if="isCarkulacja && !liczakOffer?.logo" class="w-32 md:w-auto" />
      <img v-else-if="isCarkulacja && liczakOffer?.logo" class="w-32 md:w-auto md:ml-8 block h-20 p-2" :src="liczakOffer?.logo" alt="logo">
    </template>
    <template #button>
      <div class="py-2">
        Kalkulacja finansowania samochodu
      </div>
    </template>
  </AppHeaderMinimal>

  <Container no-paddings>
    <LayoutColumns with-print>
      <LayoutMainColumn noflex class="space-y-16">
        <div class="relative">
          <div :class="{'lg:pb-6': isNew}" 
            class="shadow-box print:shadow-none print:border print:border-gray-300 -ml-4 -mr-4 sm:-mx-8 bg-gray-100 container-sized rounded-b-lg overflow-hidden">
            <div class="bg-gradient-to-t from-30% from-white via-gray-200 via-40% to-white to-100%">
              <div class="px-6 sm:px-8">
                <CarOfferPreview :header="`${brand} ${model}`" :subheader="version" :car="liczakOffer?.car"
                                 :offer="liczakOffer?.offer" liczak-view />
                  <p v-if="isNew" class="text-xs font-extralight absolute bottom-6 z-20 hidden lg:block">
                    Prezentowana grafika samochodu ma jedynie charakter poglądowy.<br/>
                    Rzeczywisty wygląd, a w szczególności kolor i wersja wyposażenia mogą odbiegać od prezentowanego na grafice.
                </p>
              </div>
            </div>
          </div>
        </div>

        <Panel class="p-6">
          <OfferHeader>Twój samochód</OfferHeader>
          <div>
            <OfferSpecs :group="liczakOffer?.car.brandName + ' ' + liczakOffer?.car.versionName">
              <OfferSpec label="Stan samochodu">
                <span class="font-normal">{{ liczakOffer?.car.state }}</span>
              </OfferSpec>
              <OfferSpec label="Rok produkcji">
                <span class="font-normal">{{ liczakOffer?.car.productionYear }}</span>
              </OfferSpec>
              <OfferSpec label="Zarejestrowany">
                <span class="font-normal">Nie</span>
              </OfferSpec>
              <OfferSpec label="Przebieg">
                <span class="font-normal">{{ liczakOffer?.car.mileage }} km</span>
              </OfferSpec>
            </OfferSpecs>
          </div>

          <Accordion :expanded="moreEquipment || pdfMode">
            <OfferHeader v-if="liczakOffer?.car.equipment.length">Wyposażenie</OfferHeader>
            <div v-if="liczakOffer?.car.equipment.length">
              <OfferEquipment v-for="(eqs, group) in equipment" :group="group" :equipment="eqs" />
            </div>

            <a id="specs"></a>
            <OfferHeader>Dane techniczne</OfferHeader>
            <div>
              <OfferSpecs group="Nadwozie">
                <OfferSpec label="Typ nadwozia">{{ liczakOffer?.car.bodyType }}</OfferSpec>
                <OfferSpec label="Liczba drzwi">{{ liczakOffer?.car.doors }}</OfferSpec>
                <OfferSpec label="Liczba siedzeń">{{ liczakOffer?.car.seats }}</OfferSpec>
              </OfferSpecs>

              <OfferSpecs group="Pojemność">
                <OfferSpec label="Standardowa pojemność bagażnika (VDA)">{{ liczakOffer?.car.trunkCapacityMin }}&nbsp;l
                </OfferSpec>
              </OfferSpecs>
            </div>
          </Accordion>

          <div class="flex justify-end mr-6" v-if="!pdfMode">
            <ButtonToggle class="font-light text-azure !fill-azure" v-model:toggled="moreEquipment" with-chevron>
              Pokaż pełne wyposażenie
              <template #toggled>Ukryj pełne wyposażenie</template>
            </ButtonToggle>
          </div>
        </Panel>

        <div class="page-break-padded" />

        <Panel class="p-6">
          <OfferHeader :header-right-class="'text-sm font-extralight'" :right="`WIBOR ${liczakOffer?.offer.wibor}%`">
            Kalkulacja finansowania
          </OfferHeader>
          <OfferTableRow no-separator>
            <OfferTableField type="label" />
            <OfferTableField class="hidden lg:block">udział [%]</OfferTableField>
            <OfferTableField>ilość rat</OfferTableField>
            <OfferTableField>netto</OfferTableField>
            <OfferTableField>brutto</OfferTableField>
          </OfferTableRow>
          <OfferTableRow>
            <OfferTableField type="label">wpłata wstępna</OfferTableField>
            <OfferTableField class="hidden lg:block">{{ liczakOffer?.offer.initialFee.value }}</OfferTableField>
            <OfferTableField>1</OfferTableField>
            <OfferTableField>{{ liczakOffer?.offer.initialFee.net }}</OfferTableField>
            <OfferTableField>{{ liczakOffer?.offer.initialFee.gross }}</OfferTableField>
          </OfferTableRow>
          <OfferTableRow>
            <OfferTableField type="label">wartość końcowa</OfferTableField>
            <OfferTableField class="hidden lg:block">{{ liczakOffer?.offer.repurchase.value }}</OfferTableField>
            <OfferTableField>1</OfferTableField>
            <OfferTableField>{{ liczakOffer?.offer.repurchase.net }}</OfferTableField>
            <OfferTableField>{{ liczakOffer?.offer.repurchase.gross }}</OfferTableField>
          </OfferTableRow>
          <OfferTableRow class="text-azure" no-separator>
            <OfferTableField type="label">Twoja rata leasingowa</OfferTableField>
            <OfferTableField class="hidden lg:block" />
            <OfferTableField :large="liczakOffer?.offer.client === 'consumer'">
              {{ liczakOffer?.offer.installment.amount }}
            </OfferTableField>
            <OfferTableField :large="liczakOffer?.offer.client === 'entrepreneur'">
              {{ liczakOffer?.offer.installment.net[liczakOffer?.offer.installment.amount] }}
            </OfferTableField>
            <OfferTableField> {{ liczakOffer?.offer.installment.gross[liczakOffer?.offer.installment.amount] }}
            </OfferTableField>
          </OfferTableRow>
          <OfferTableRow no-separator>
            <div class="flex flex-wrap justify-between w-full mt-6">
              <div class="w-1/2 lg:w-auto lg:flex-grow-0 pl-0 lg:p-2" v-if="liczakOffer?.offer.financingOffer.financingForm">
                <p class="font-extralight text-xs">Forma finansowania</p>
                <p class="text-sm font-light">{{ liczakOffer?.offer.financingOffer.financingForm }}</p>
              </div>
              <div class="w-1/2 lg:w-auto lg:flex-grow-0 lg:p-2" v-if="liczakOffer?.offer.financingOffer.way">
                <p class="font-extralight text-xs">Sposób nabycia samochodu</p>
                <p class="text-sm font-light">{{ liczakOffer?.offer.financingOffer.way }}</p>
              </div>
              <div class="w-1/2 lg:w-auto lg:flex-grow-0 p-2 pl-0" v-if="liczakOffer?.offer.financingOffer.contractPeriod">
                <p class="font-extralight text-xs">Okres umowy</p>
                <p class="text-sm font-light">{{ liczakOffer?.offer.financingOffer.contractPeriod }}</p>
              </div>
              <div class="w-1/2 lg:w-auto lg:ml-auto pt-2 lg:p-2" v-if="liczakOffer?.offer.financingOffer.installmentsSum">
                <p class="font-extralight text-xs">Suma rat leasingowych</p>
                <p class="text-sm font-light">{{ liczakOffer?.offer.financingOffer.installmentsSum }}%</p>
              </div>
            </div>
          </OfferTableRow>
        </Panel>
        <Panel class="p-6">
          <OfferHeader v-if="!liczakOffer?.offer.hideInsurance" :header-right-class="'text-sm font-extralight max-w-[326px] md:text-right 2xl:max-w-full'"
                       :right="liczakOffer?.offer.insuranceTableLabel">Kalkulacja ubezpieczenia
          </OfferHeader>
          <OfferTableRow no-separator v-if="!liczakOffer?.offer.hideInsurance && !liczakOffer?.offer.hideInsuranceInstallment">
            <OfferTableField type="label" />
            <OfferTableField></OfferTableField>
            <OfferTableField>ilość rat</OfferTableField>
            <OfferTableField>netto</OfferTableField>
            <OfferTableField>brutto</OfferTableField>
          </OfferTableRow>

          <template v-if="!liczakOffer?.offer.hideInsurance && liczakOffer?.offer.insurance.firstOption.installment.amount && !liczakOffer?.offer.hideInsuranceInstallment">
            <OfferTableRow v-for="(value, amount, idx) in liczakOffer?.offer.insurance.firstOption.installment.value" :no-separator="idx !== Object.keys(liczakOffer?.offer.insurance.firstOption.installment.value).length - 1">
              <OfferTableField type="label">{{ !idx ? liczakOffer?.offer.insurance.firstOption.installment.label : "" }}</OfferTableField>
              <OfferTableField></OfferTableField>
              <OfferTableField>{{ amount }}</OfferTableField>
              <OfferTableField>{{ value.net }}</OfferTableField>
              <OfferTableField>{{ value.gross }}</OfferTableField>
            </OfferTableRow>
          </template>

          <template v-if="liczakOffer?.offer.hideInsuranceInstallment">
            <OfferTableRow :no-separator="true">
              <OfferTableField type="label">{{ liczakOffer?.offer.insurance.firstOption.installment.label}}</OfferTableField>
              <OfferTableField></OfferTableField>
              <OfferTableField>składka i rata wyliczana indywidualnie</OfferTableField>
            </OfferTableRow>
          </template>


          <template v-if="!liczakOffer?.offer.hideInsurance && liczakOffer?.offer?.gapEnabled">
          <OfferTableRow v-for="(value, amount, idx) in liczakOffer?.offer.insurance.secondOption.installment.value" no-separator>
            <OfferTableField type="label">{{ liczakOffer?.offer.insurance.secondOption.installment.label }}</OfferTableField>
            <OfferTableField></OfferTableField>
            <OfferTableField>{{ amount }}</OfferTableField>
            <OfferTableField>{{ value.net }}</OfferTableField>
            <OfferTableField>{{ value.gross }}</OfferTableField>
          </OfferTableRow>
          </template>
          <template v-if="liczakOffer?.offer.client !== 'consumer' && liczakOffer?.offer.showDrivePlus">
            <OfferHeader :class="{'mt-6': !liczakOffer?.offer.hideInsurance}">Pakiet DRIVE+</OfferHeader>
            <OfferTableRow no-separator>
              <OfferTableField type="label" />
              <OfferTableField></OfferTableField>
              <OfferTableField>ilość rat</OfferTableField>
              <OfferTableField>netto</OfferTableField>
              <OfferTableField>brutto</OfferTableField>
            </OfferTableRow>
            <OfferTableRow no-separator v-if="liczakOffer?.offer.drivePlus.amount">
              <OfferTableField type="label">doliczony do miesięcznej raty leasingowej</OfferTableField>
              <OfferTableField></OfferTableField>
              <OfferTableField>{{ liczakOffer?.offer.drivePlus.amount }}</OfferTableField>
              <OfferTableField>{{ liczakOffer?.offer.drivePlus.net }}</OfferTableField>
              <OfferTableField>{{ liczakOffer?.offer.drivePlus.gross }}</OfferTableField>
            </OfferTableRow>
          </template>

        </Panel>
        <Panel class="p-6 border-2 border-azure text-azure" v-if="liczakOffer?.offer.installmentSummary.amount">
          <OfferTableRow no-separator>
            <OfferTableField class="text-sm lg:text-xl" type="label">Twoja całkowita rata</OfferTableField>
            <OfferTableField></OfferTableField>
            <OfferTableField>{{ liczakOffer?.offer.installmentSummary.amount }}</OfferTableField>
            <OfferTableField :class="{'text-sm lg:text-xl': liczakOffer?.offer.client === 'entrepreneur'}">{{ liczakOffer?.offer.installmentSummary.net }}</OfferTableField>
            <OfferTableField :class="{'text-sm lg:text-xl': liczakOffer?.offer.client === 'consumer'}">{{ liczakOffer?.offer.installmentSummary.gross }}</OfferTableField>
          </OfferTableRow>
        </Panel>

        <Panel class="p-6" v-if="isAdditionalFeesVisible">
          <OfferHeader>Opłaty dodatkowe</OfferHeader>
          <OfferTableRow no-separator>
            <OfferTableField type="label" />
            <OfferTableField></OfferTableField>
            <OfferTableField></OfferTableField>
            <OfferTableField>netto</OfferTableField>
            <OfferTableField>brutto</OfferTableField>
          </OfferTableRow>
          <OfferTableRow>
            <OfferTableField type="label">zwrot kosztów rejestracji samochodu</OfferTableField>
            <OfferTableField></OfferTableField>
            <OfferTableField></OfferTableField>
            <OfferTableField>{{ liczakOffer?.offer.additionalFees.costReimbursement.net }}</OfferTableField>
            <OfferTableField>{{ liczakOffer?.offer.additionalFees.costReimbursement.gross }}</OfferTableField>
          </OfferTableRow>
          <OfferTableRow :no-separator="!liczakOffer?.offer.additionalFees.additionalInsuranceCost">
            <OfferTableField type="label">roczna opłata administracyjna</OfferTableField>
            <OfferTableField></OfferTableField>
            <OfferTableField></OfferTableField>
            <OfferTableField>{{ liczakOffer?.offer.additionalFees.annualFee.net }}</OfferTableField>
            <OfferTableField>{{ liczakOffer?.offer.additionalFees.annualFee.gross }}</OfferTableField>
          </OfferTableRow>
          <OfferTableRow v-if="liczakOffer?.offer.additionalFees.additionalInsuranceCost" no-separator>
            <OfferTableField type="label">opłata za ubezpieczenie OC/AC/NNW poza ofertą VEHIS</OfferTableField>
            <OfferTableField></OfferTableField>
            <OfferTableField></OfferTableField>
            <OfferTableField>{{ liczakOffer?.offer.additionalFees.additionalInsuranceCost.net }}</OfferTableField>
            <OfferTableField>{{ liczakOffer?.offer.additionalFees.additionalInsuranceCost.gross }}</OfferTableField>
          </OfferTableRow>
        </Panel>
        <BlockDrivePlus :title="drivePlusHeading" :no-assistance="!(liczakOffer?.car.type ===  'passenger')" />
        <p class="text-base font-extralight !mb-6">
            Niniejszy materiał nie stanowi oferty w rozumieniu art. 66 K.C. i ma charakter wyłącznie informacyjny. Ostateczne warunki umowy zależą od oceny zdolności finansowanej oraz finansowanego przedmiotu i zostaną wskazane w odpowiedniej umowie, po złożeniu wniosku.
        </p>
        <p class="text-base font-extralight !mt-0">
            Kalkulacja przygotowana przez: VEHIS Finanse Sp. z o.o., 40154 Katowice, Al. Korfantego 141, KRS 0000791146, NIP: 5272896510, kapitał zakładowy 385000
        </p>
        <div class="page-break"></div>

        <div>
          <SubContentHeader>Korzyści oferty
            <span v-if="!isCarkulacja">VEHIS</span>
          </SubContentHeader>
          <StaticGains />
        </div>
        <div v-if="!isCarkulacja" class="flex flex-col items-center md:items-start pb-16 md:pb-0">
          <p class="text-2xl mb-6 text-center md:text-left">Jesteśmy członkiem ZPL, największej organizacji reprezentującej branżę leasingową w Polsce</p>
          <div class="flex justify-center w-full">
            <VectorZpl class="block pl-6" />
          </div>
        </div>

        <div>
          <SubContentHeader class="mt-[-110px] md:mt-0">Nasza oferta łączy najlepsze cechy finansowania
            samochodu
          </SubContentHeader>
          <ContentLeasingFeaturesTable class="mt-6" no-button narrow />
        </div>
        <div>
          <SeoLiczakOffer v-if="!(domain.includes('carkulacja'))" />
          <SeoCarculacjaFooter v-else-if="domain.includes('carkulacja')" />
        </div>
      </LayoutMainColumn>
      <LayoutSideColumn>

        <CarOfferFloatingBoxLiczak class="hidden lg:block" :car="liczakOffer?.car" :offer="liczakOffer?.offer"
                                   :imageOnScroll="300" :no-button="pdfMode" />
      </LayoutSideColumn>
    </LayoutColumns>
    <AppFooterBarebone v-if="!(domain.includes('carkulacja'))" />
    <SeoKalkulatorVehisDisclaimer v-if="domain.includes('vehistools')" />
  </Container>
  <Blend />
</template>
